import React from "react";
import {BrowserView, MobileView} from 'react-device-detect';
import './CSS/TutoringPage.css';


const Tutoring = () => {
  const renderTutoring = () => {
    return (
      <div style={{ textAlign: 'left', maxWidth: '700px', margin: '0 auto' }}>
        <div style={{ textAlign: 'left', maxWidth: '700px', margin: '0 auto', padding: '10px' }}>
          <section style={{
            width: '100%',
          }}>
            <h2 style={{ marginBottom: '20px' }}>Visit the Professional Websites:</h2>
            <div>
              <p>This website is shared with a partner - we tutor in Computer Science, Finance, and Career/Internship advice. 
                Our rates are £60/h, with a first hour set to be free. Also on the website, you can find our blog and newsletter
                to stay up to date with internship, computer science, and finance opportunities and news.</p>
              <a 
                href="https://yourtutoringwebsite.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="button-21" style={{ marginRight: "1em", marginBottom: '10px' }}>Tutoring Website</button>
              </a>
              <p style={{ marginTop: '1em' }}>Superprof is a website to help connect students to tutors. It is a very robust site, 
                with a good track record. If you are unsure about tutoring with me, and would rather do it through a safe and 
                trusted broker, using superprof might be ideal. Be aware however that they charge a monthly fee in addition
                to the tutoring costs.
              </p>
              <a 
                href="https://www.superprof.co.uk/analyst-large-financial-institution-and-recent-msci-computer-science-graduate-from-the-university-glasgow-teaches-computer.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="button-21" style={{ marginBottom: '10px', marginRight: '0.5em' }}>Superprof Computer Science</button>
              </a>
              <a 
                href="https://www.superprof.co.uk/analyst-large-financial-institution-reveals-strategies-get-top-jobs-computer-science-fintech-and-finance.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="button-21" style={{ marginBottom: '10px' }}>Superprof Internship Advice</button>
              </a>
            </div>
          </section>
        </div>

        <br />

        <section>
          <h2>What I Offer - Internship Advice</h2>
          <p>
            With so much pressure on students to get internships, jobs, or work on personal projects, 
            finding what is important for your career can be overwhelming. There is so much content online 
            advocating different things — sometimes contradicting itself, or haphazardly structured, or straight-up wrong. 
            Having been through the process myself and completed internships at large financial institutions such as JPMorgan 
            and Goldman Sachs, I can explain to you, in a structured and detailed way, what is important to build your professional profile.
          </p>
          <p>
            This goes beyond typical CV advice or simple "tips and tricks". Instead, it provides a comprehensive and concrete 
            knowledge base on what to apply for, where to find these jobs, when to submit applications, what projects to complete, 
            who to connect with, and how to stand out in the hiring process.
          </p>
          <p>
            If you tutor with me, I guarantee that you will gain a solid understanding of the job market for new computer science 
            and finance graduates, emerging as a strong candidate in a competitive crowd. This tutoring is for anyone — whether 
            you're finishing A-levels, in the early years of your degree, nearing graduation, just starting your career, or considering 
            a career switch; I believe I can offer you insight that you can't easily find online.
          </p>
        </section>

        <br />
  
        <section>
          <h2>What I Offer - Computer Science</h2>
          <p>
            I focus on course material for students preparing for exams by honing in on the exact knowledge that is needed. 
            I identify which topics the student struggles with the most and tackle them one by one, starting from the root cause. 
            This leads to a greater fundamental understanding of the material and promotes more independent thinking.
          </p>
          <p>
            For those wanting to learn programming, I start with the basics and gradually build up to more complex concepts. 
            I teach paradigms — general strategies for structuring projects and code in the most efficient and logical way. 
            My classes typically last an hour as I find longer sessions tend to reduce focus.
          </p>
          <p>
            I can teach GCSE/A-level, university students needing extra help in specific topics, or anyone wanting to learn 
            programming and computer science concepts. I have experience teaching Python, Java, and C, along with broader 
            topics like frontend, backend, and full stack development, or even video game development.
          </p>
        </section>

        <br />
  
        <section>
          <h2>Student Reviews</h2>
          <ul>
            <li>
              <strong>John D.</strong>: "Excellent tutor! Helped me understand Python from the basics to advanced concepts. 
              Very knowledgeable and patient. Highly recommended!" <strong>Rating: 5/5</strong>
            </li>
            <li>
              <strong>Emily R.</strong>: "The way he breaks down complex programming paradigms is amazing. I feel much more 
              confident about my university projects now." <strong>Rating: 5/5</strong>
            </li>
            <li>
              <strong>Michael P.</strong>: "His insights into the job market are invaluable. I was able to secure an internship 
              at a top tech company after working with him!" <strong>Rating: 5/5</strong>
            </li>
            <li>
              <strong>Sarah L.</strong>: "Helped me immensely in preparing for my A-level computer science exams. His approach 
              really clicked for me, and I saw great improvements in my understanding." <strong>Rating: 5/5</strong>
            </li>
          </ul>
        </section>
      </div>
    );
  };
  
  
  return (<>
    <BrowserView>
      <div className="cv-container">
        <div className="title">
          <h1>Tutoring</h1>
          <hr />
          {renderTutoring()}
        </div>
      </div>
    </BrowserView>
    <MobileView>
      <div className="cv-container-mobile">
        <div className="title-mobile">
          <h1>Tutoring</h1>
          <hr />
        </div>
        {renderTutoring()}
      </div>
    </MobileView>
  </>);
}

export default Tutoring;