import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import HomePage from './pages/HomePage.js';
import BlogPage from './pages/BlogPage.js';
import ProjectsPage from './pages/ProjectsPage.js';
import ContactPage from './pages/ContactPage.js';
import BasePage from './pages/BasePage.js';
import BlogPostBase from './pages/BlogPostBase';
import React from 'react'
import Tutoring from './pages/Tutoring.js';


function App() {

  return (
    <BrowserRouter>
      <Routes> 
        <Route path="/" element={<BasePage />}>
          <Route index element={<HomePage />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/tutoring" element={<Tutoring />} />
          <Route path="/blog/:blogName" element={<BlogPostBase />} />
          <Route path="*" element={<></>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
