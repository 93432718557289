import React, { useState, useEffect } from 'react';
import './CSS/HomeLowerPage.css';
import {BrowserView, MobileView} from 'react-device-detect';
import { RiArrowDropDownLine, RiArrowDropRightLine } from 'react-icons/ri'


const HomeLowerPage = () => {
  const [titles, setTitles] = useState({
    education: [
      { title: "MSci at the University of Glasgow", subTitles: [
        {"text": "Favourite subjects: AI, Text as Data, ML, Deep Learning, Website Group Project, Text as Data (NLP), Information Retrieval"},
        {"text": "Bachelor's Dissertation topic: Speeding up Spaceship Search using Deep Learning in John Conway’s Game of Life. Designed a neural network for Game of Life spaceship search using PyTorch and other data science tools"},
        {"text": "Master's Dissertation topic: Inferring phylogenetic trees using deep reinforcment learning."},
        {"text": "Final grade: 1st Class with full Honors"},
        {"text": "Computer Science Faster Route", "link":"https://www.gla.ac.uk/undergraduate/degrees/computingscience/#coursedetails"}
      ], clicked: false },
      { title: "A-levels at International school in France", subTitles: [
        {"text": "A-levels: Math (A*), Physics (A*) and Computer Science (A*)"},
        {"text": "International School of Bearn", "link": "https://www.isbearn.com/"}
      ], clicked: false },
      { title: "Personal Interest Projects", subTitles: [
        {"text": "Economic and Financial Modelling"},
        {"text": "European/French Politics, and Geopolitics"}
      ]}
    ],
    experience: [
      { title: "Goldman Sachs", subTitles: [
        {"text": "Developed SRE software to probe a website (300+ daily users) using Kubernetes and Micronaut"},
        {"text": "Allowed the release of new features thanks to proof of functionality"},
        {"text": "Applied AGILE methods, created enterprise level code, learned about fintech"},
        {"text": "Presented and drove adoption of a new software stack to a 50+ member audience"},
        {"text": "Goldman Sachs Website", "link": "https://www.goldmansachs.com/"}
      ], clicked: false },
      { title: "JPMorgan", subTitles: [
        {"text": "Enhanced a website for 500+ employees using Spring and React"},
        {"text": "Crafted enterprise-level code, documented extensively, and deepened expertise in finance"},
        {"text": "Effectively employed Jira, Confluence, and other enterprise-level workflow applications"},
        {"text": "JPMorgan Website", "link": "https://www.jpmorgan.com/"}
      ], clicked: false },
      { title: "Crimson Education Tutor", subTitles: [
        {"text": "Tutored 50+ students with a rating of 5/5 stars"},
        {"text": "Crimson Education", "link": "https://www.crimsoneducation.org/"}
      ], clicked: false },
      { title: "GUSS Software Engineer", subTitles: [
        {"text": "Involves meeting with clients, discussing project priorities & implementing them as a team"},
        {"text": "We create websites, mobile apps, and research software (wide variety of topics)"},
        {"text": "Glasgow University Software Service", "link": "https://www.gla.ac.uk/schools/computing/industry/glasgowuniversitysoftwareserviceguss/"}
      ], clicked: false},
      { title: "Research Assistant", subTitles: [
        {"text": "Co-writer of “Are Thermal Attacks a Realistic Threat? Investigating the Preconditions of Thermal Attacks in Users’ Daily Lives” published in NordCHI’22 and cited by 5."},
        {"text": "Link to paper", "link" : "https://dl.acm.org/doi/10.1145/3546155.3546706"},
        {"text": "Innovated a music sharing social media app in android studio as a proof of concept"},
        {"text": "University of Glasgow Website", "link": "https://www.gla.ac.uk/"}
      ]}
    ],
    achievements: [
      { title: "Goldman Sachs Hackathon", subTitles: [
        {"text": "Led the development of a website in a Goldman Sachs hackathon using React and NodeJS"},
        {"text": "2nd Place"}
      ], clicked: false },
      { title: "Goldman Sachs LLM Hackathon", subTitles: [
        {"text": "used huggingface models and FewShot learning to adapt a model to predict financial sentiment on a set of tweets/news articles"},
        {"text": "1st Place"}
      ], clicked: false },
      { title: "GUTS hackathon", subTitles: [
        {"text": "full stack React website with NodeJS backend)"},
        {"text": "3rd Place"}
      ], clicked: false },
    ],
  });

  const handleItemClick = (category, index) => {
    const updatedTitles = { ...titles };
    let prev = updatedTitles[category][index].clicked;
    if (Array.isArray(updatedTitles[category])) {
      for (let i = 0; i < updatedTitles[category].length; i++) {
        if (updatedTitles[category][i].clicked) {
          updatedTitles[category][i].clicked = !updatedTitles[category][i].clicked;
        }
      }
    }
    updatedTitles[category][index].clicked = !prev;
    setTitles(updatedTitles);
  };

  const setEqualHeight = () => {
    const columnElements = document.querySelectorAll('.split-experience');
    let maxHeight = 0;

    columnElements.forEach((element) => {
      const height = element.getBoundingClientRect().height;
      if (height > maxHeight) {
        maxHeight = height;
      }
    });

    columnElements.forEach((element) => {
      element.style.height = `${maxHeight}px!important`;
    });
  };

  // Use useEffect to call setEqualHeight whenever the content changes
  useEffect(() => {
    setEqualHeight();
  }, [titles]);


  const generateListItem = (titleList, category) => {
    return (
      <ul>
        {titleList.map((item, index) => (
          <li
            key={index}
            className={`li-main ${item.clicked ? "li-main-clicked" : ""} ${
              index + 1 === titleList.length ? " li-main-last" : ""
            }`}
            onClick={() => handleItemClick(category, index)}
          >
            {item.clicked ? <RiArrowDropDownLine size={20} /> : <RiArrowDropRightLine size={20} />}
            {item.title}
            {item.subTitles.length > 0 && item.clicked && (
              <ol>
                {item.subTitles.map((subItem, subIndex) => (
                  <li key={subIndex} className="li-sub">
                    {subItem.link != undefined ? <a href={subItem.link} target="_blank"><p style={{"color":"blue", "margin-bottom":"0"}}>{subItem.text}</p></a> : <>{subItem.text}</>}
                  </li>
                ))}
              </ol>
            )}
          </li>
        ))}
      </ul>
    );
  };
  

  return (<>
    <BrowserView>
      <div className="d-flex">
        <div className="split-experience">
          <div className="title-homelower"><h1>Education</h1></div>
          {generateListItem(titles.education, 'education')}
        </div>
        <div className="split-experience">
          <div className="title-homelower"><h1>Experience</h1></div>
          {generateListItem(titles.experience, 'experience')}
        </div>
        <div className="split-experience">
          <div className="title-homelower"><h1>Achievements</h1></div>
          {generateListItem(titles.achievements, 'achievements')}
        </div>
      </div>
    </BrowserView>
    <MobileView>
    <div className="mobile-div">
        <div className="mobile-experience">
          <div className="title-homelower"><h1>Education</h1></div>
          {generateListItem(titles.education, 'education')}
        </div>
        <br />
        <div className="mobile-experience">
        <div className="title-homelower"><h1>Experience</h1></div>
          {generateListItem(titles.experience, 'experience')}
        </div>
        <br />
        <div className="mobile-experience">
        <div className="title-homelower"><h1>Achievements</h1></div>
          {generateListItem(titles.achievements, 'achievements')}
        </div>
      </div>
    </MobileView>
    </>
  );
};

export default HomeLowerPage;
